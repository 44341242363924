(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expandable-area/assets/javascripts/expandable-area.js') >= 0) return;  svs.modules.push('/components/tipsen/expandable-area/assets/javascripts/expandable-area.js');
"use strict";

const {
  useRef,
  useEffect,
  useMemo
} = React;
const ExpandableArea = _ref => {
  let {
    arrowPosition,
    children,
    className,
    'data-testid': dataTestId,
    isOpen,
    isCouponMenu
  } = _ref;
  const expandableAreaClassNames = useMemo(() => {
    const _expandableAreaClassNames = ['expandable-area'].concat(className !== null && className !== void 0 ? className : []);
    if (isOpen) {
      _expandableAreaClassNames.push('expandable-area-open');
      if (isCouponMenu) {
        _expandableAreaClassNames.push('expandable-area-coupon-menu');
      }
    }
    return _expandableAreaClassNames;
  }, [isOpen, className]);
  const expandableAreaRef = useRef();
  useEffect(() => {
    expandableAreaRef.current.style.setProperty('--arrow-position', "".concat(arrowPosition, "px"));
  }, [arrowPosition]);
  return React.createElement("div", {
    className: expandableAreaClassNames.join(' '),
    "data-testid": dataTestId,
    ref: expandableAreaRef
  }, children);
};
setGlobal('svs.components.tipsen.expandableArea.ExpandableArea', ExpandableArea);

 })(window);